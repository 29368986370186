const baseUrl = process.env.baseUrl;

export const state = () => ({
    outOfWorkDays: [],
    employees: [],
    dayToEdit: null
});

export const mutations = {
    setOutOfWorkDays (state, outOfWorkDays) {
      state.outOfWorkDays = [];
      for (let day of outOfWorkDays) {
        state.outOfWorkDays.push(day)
      }
        // state.outOfWorkDays = outOfWorkDays;
    },
    addOutOfWorkDay (state, outOfWorkDay) {
        state.outOfWorkDays.push(outOfWorkDay);
    },
    removeOutOfWorkDay (state, outOfWorkDay) {
        for (let ev in state.outOfWorkDays) {
          const day = state.outOfWorkDays[ev];
          if (day.id === outOfWorkDay.id) {
            console.log(day.id)
            state.outOfWorkDays.splice(ev, 1)
          }
        }
    },
    updateOutOfWorkDay (state, outOfWorkDay) {
      for (let ev in state.outOfWorkDays) {
        const day = state.outOfWorkDays[ev];
        if (day.id === outOfWorkDay.id) {
          console.log(day.id)
          state.outOfWorkDays.splice(ev, 1);
          state.outOfWorkDays[ev] = outOfWorkDay;
        }
      }
    },
    insertDayToEdit (state, outOfWorkDay) {
      state.dayToEdit = outOfWorkDay;
    },
    removeDayToEdit (state) {
      state.dayToEdit = null;
    },
    addEmployees (state, employees) {
      state.employees.push(employees);
    },

};

export const getters = {
    outOfWorkDays (state) {
        return state.outOfWorkDays
    }
};

export const actions = {

    async getOutOfWorkDays (context, year, month) {
        const url = `${baseUrl}/api/v1/out-of-work-days/`;
        const days = await this.$axios.$get(url, {
            params: {year, month}
            })
          .then((res) => {
            context.commit('setOutOfWorkDays', res)
          })
          .catch(err => console.error(err));
      return days;
    },
    async addOutOfWorkDay (context, outOfWorkDay) {
        const url = `${baseUrl}/api/v1/out-of-work-days/`;
        return await this.$axios.$post(url, outOfWorkDay)
          .then((res) => {
            console.log('Days ', res);
            context.commit('setOutOfWorkDays', res);
            return res;
          })
          .catch(err => console.error(err));
        },

    async addRangeOutOfWorkDays(context, outOfWorkDays) {
        const url = `${baseUrl}/api/v1/out-of-work-days/`;
        return await this.$axios.$post(url, outOfWorkDays)
          .then((res) => {
            context.commit('setOutOfWorkDays', res);
            return res;
          })
          .catch(err => console.error(err));
    },

    async updateOutOfWorkDay (context, outOfWorkDay) {
      // console.log(outOfWorkDay);
      const url = `${baseUrl}/api/v1/out-of-work-days/${outOfWorkDay.id}/`;
      return await this.$axios.$put(url, outOfWorkDay)
        .then((res) => {
          context.commit('updateOutOfWorkDay', res)
          console.log(res)
          return res;
        })
        .catch(err => console.error(err));},

    async deleteOutOfWorkDay (context, outOfWorkDay) {
      console.log(outOfWorkDay);
      const url = `${baseUrl}/api/v1/out-of-work-days/${outOfWorkDay.id}`;
      return await this.$axios.$delete(url)
        .then((res) => {
          console.log(res);
          context.commit('removeOutOfWorkDay', outOfWorkDay);
          return res;
        })
        .catch(err => console.error(err));
    },


  async getRangeOutOfWorkDays (context, data) {
    this.$axios({
      url: `${baseUrl}/api/v1/create-excel/`,
      method: 'GET',
      responseType: 'blob',
      params: data
    }).then((response) => {
      console.log(response.request);
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');

      fileLink.href = fileURL;
      const fileName = data['date_from'] + '-' + data['date_to'];
      fileLink.setAttribute('download', fileName + '.xls');
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  },
  async getEmployees (context, rangeDates) {
    const url = `${baseUrl}/api/v1/employees/`;
    return await this.$axios.$get(url)
      .then((res) => {
        context.commit('addEmployees', res);
        return res;
      })
      .catch(err => console.error(err));
  },


};
