import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7941831a = () => interopDefault(import('../pages/AdminView.vue' /* webpackChunkName: "pages/AdminView" */))
const _e9a5f770 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _27848d3b = () => interopDefault(import('../pages/Logout.vue' /* webpackChunkName: "pages/Logout" */))
const _4a134151 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AdminView",
    component: _7941831a,
    name: "AdminView"
  }, {
    path: "/Login",
    component: _e9a5f770,
    name: "Login"
  }, {
    path: "/Logout",
    component: _27848d3b,
    name: "Logout"
  }, {
    path: "/",
    component: _4a134151,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
