import Vue from 'vue';
import vueCalendar from 'vue2-simple-calendar';
import "~/assets/vue2-simple-calendar.css";


Vue.use(vueCalendar, {
  firstDay: 1,
  showLimit: 5,
  locale: 'it',
  fullDayNames: false,
  fullMonthNames: false,
  componentName: 'vue-calendar',
  languages: {
    it: {
      showMore: 'Mostra di più',
      dayNameShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNameLong: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato',],
      monthNameShort: ['Gen', 'Feb', 'Mar.', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      monthNameLong: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
    },
  }

});
