import { ToastProgrammatic as Toast, NotificationProgrammatic as Notification } from 'buefy'

export default function ({ $axios, redirect, store }) {
    $axios.onError(err => {
        const code = parseInt(err.response && err.response.status)
        if(code === 401) {
            let message
            if (store.getters['auth/tokenHasExpired']) {
                store.dispatch('auth/destroyToken')
                redirect('/login')
                message = 'Your authentication token has expired'
            }
            else {
                message = 'Unauthorized request'
            }
            Toast.open({
                message: message,
                type: 'is-danger'
            })
            return
        }
        Notification.open({
            duration: 7500,
            message: `<b>Network request has failed</b> (open console for more info).`,
            position: 'is-top',
            type: 'is-danger',
            hasIcon: false
        })
    })
}
