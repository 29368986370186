export const state = () => ({
    posts: [],
    currentPost: null
})

export const mutations = {
    setPosts (state, posts) {
        state.posts = posts
    }
}

export const getters = {
    postsByUser1 (state) {
        return state.posts.filter(p => p.userId === 1)
    }
}

export const actions = {
    async getPosts (context) {
        const posts = await this.$axios.$get('https://jsonplaceholder.typicode.com/posts')
            .catch(err => console.error(err))
        context.commit('setPosts', posts)
    }
}
