import jwt from '~/utils/jwt'
import Vue from "vue";
const baseUrl = process.env.baseUrl;



const getUser = () => {
        try {
            return JSON.parse(localStorage.getItem('user'))
        } catch (error) {
            console.error('Error while parsing user object from localStorage:', error);
            return undefined
        }
};

export const state = () => ({
    token: localStorage.getItem('access_token') || null,
    user: getUser() || null
});

export const getters = {
    loggedIn(state) {
        return state.token !== null
    },
    tokenHasExpired (state) {
        return jwt.hasExpired(state.token)
    },
    userID(state) {
        return jwt.getUserID(state.token)
    }
};

export const mutations = {
    setToken(state, token) {
        state.token = token
    },
    destroyToken(state) {
        state.token = null
    },
    setUser(state, user) {
        localStorage.setItem('user', JSON.stringify(user));
        state.user = user
    },
    unsetUser(state) {
        localStorage.removeItem('user');
        state.user = null
    }
}

export const actions = {
    async retrieveToken(context, credentials) {
        const url = `${baseUrl}/api/v1/token/`;
        const res = await this.$axios.$post(
            url,
            {
                username: credentials.username,
                password: credentials.password
            }
          )
          .then((res) => {
            console.log('*** auth res', res);
            const token = res.access;
            localStorage.setItem('access_token', token);
            context.commit('setToken', token);
            this.$axios.setToken(token, 'Bearer');
            return res
          })
          .catch(err => {
            console.error('LOGIN ERROR', err);
          });
      return res;
    },
    async refreshToken(context) {
      const url = `${baseUrl}/api/v1/token/refresh`;
      const res = await this.$axios.$post(
        url,
        {
          access: state.token['access'],
          refresh: state.token['refresh']
        }
      )
      .then((res) => {
        console.log('*** auth res', res);
        const token = res.access;
        localStorage.setItem('access_token', token);
        context.commit('setToken', token);
        this.$axios.setToken(token, 'Bearer')
      }).catch(err => console.error('LOGIN ERROR', err))
    },
    destroyToken(context) {
        if (context.getters.loggedIn) {
            localStorage.removeItem('access_token');
            context.commit('destroyToken');
            this.$axios.setToken(false);
            context.commit('unsetUser')
        }
    },
    setAuthHeader(context, token) {
        this.$axios.setToken(token, 'Bearer')
    }
}
