<template>
  <div>
    <b-navbar class="is-light is-primary" role="navigation" aria-label="main navigation">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="~assets/logo.png"
               alt="Lightweight UI components for Vue.js based on Bulma"
          >
        </b-navbar-item>
      </template>
      <section class="main-content columns">

        <div class="container column is-10s">
          <nuxt />
        </div>
      </section>
      <template slot="end" v-if="loggedIn">
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-light" @click="logout">
              {{ $t('CALENDAR.logout') }}
            </a>
          </div>
        </b-navbar-item>
        <b-navbar-item>
          <b-select v-model="selected" :selected="changeLanguage" placeholder="Country" icon="earth" >
            <option value="it">{{ $t('CALENDAR.italian') }}</option>
            <option value="en">{{ $t('CALENDAR.english') }}</option>
          </b-select>
        </b-navbar-item>
      </template>
    </b-navbar>

    <section class="main-content columns">

      <div class="container column is-10s">
        <nuxt />
      </div>
    </section>

    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>EvaCal</strong> by <a href="https://energievalsabbia.it">Energievalsabbia</a>.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
  import vueCalendar from "vue2-simple-calendar";


  export default {
    data () {
      return {
        items: [
          {
            title: 'Home',
            icon: 'home',
            to: { name: 'index' }
          },
          {
            title: 'Inspire',
            icon: 'lightbulb',
            to: { name: 'inspire' }
          }
        ],
        // selected: this.$i18n.locale
        selected: this.$i18n.defaultLocale
      }
    },
    methods:{
      logout () {
        this.$store.dispatch('auth/destroyToken');
        this.$router.push({ path: '/logout' });
      }
    },
    computed: {
      ...mapGetters('auth', [
        'loggedIn'
      ]),
      changeLanguage() {
        this.$root.$i18n.locale = this.selected;
      }
    }
  }
</script>

