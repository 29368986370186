const baseUrl = process.env.baseUrl;


export const state = () => ({
    employees: [],
});

export const mutations = {
    // setOutOfWorkDays (state, outOfWorkDays) {
    //     state.outOfWorkDays = outOfWorkDays
    // }
};

export const getters = {
    // outOfWorkDays (state) {
    //     return state.outOfWorkDays
    // }
};

export const actions = {
  async getEmployees (context, rangeDates) {
    const url = `${baseUrl}/api/v1/employees/`;
    return await this.$axios.$get(url)
      .then((res) => {
        return res;
      })
      .catch(err => console.error(err));
    // context.commit('setOutOfWorkDays', days)
  },
};
