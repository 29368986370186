const getPayload = (token) => {
    return JSON.parse(window.atob(token.split('.')[1]))
}

const hasExpired = (token) => {
    const payload = getPayload(token)
    return payload.exp < Date.now() / 1000
}

const getUserID = (token) => {
    const payload = getPayload(token)
    return payload.user_id
}

const jwt = {
    getPayload,
    hasExpired,
    getUserID,
}

export default jwt